import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const Title = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  a {
    font-size: 48px;
    color: var(--color-text);
    background-color: var(--color-secondary);
    padding: 16px 32px;
    border-radius: 6px;
  }
`;

const MissingPage = () => {
  return (
    <Title>
      <h1>404</h1>
      <p>
        Oh no, you hit a dead end. Thats okay, heres a link to get you back to
        safety
      </p>
      <svg
        width="87"
        height="75"
        viewBox="0 0 187 175"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M50 75L93.3013 150H6.69873L50 75Z" fill="#FFDF8E" />
        <path d="M137 75L180.301 150H93.6987L137 75Z" fill="#FFDF8E" />
        <path d="M94 0L137.301 75H50.6987L94 0Z" fill="#FFDF8E" />
      </svg>
      <br></br>
      <Link to="/">Link</Link>
    </Title>
  );
};

export default MissingPage;
